import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Axios ดึงข้อมูล Api
import axios from "axios";
// Page Annimation
import { animated, useSpring } from "react-spring";

// Style
import "./Product.css";
import EmailJs from "../Contener/Contact/EmailJs";

function Product() {
  // ค้นหาแบบกลุ่ม
  const [SeleGR, setSeleGR] = useState("");

  // ดึงข้อม Api
  const [Data, setData] = useState([]);
  const [oneRead, setoneread] = useState();

  // ค้นหาแบบ ชื่อข้อมูล
  const [Searching, setSearching] = useState();
  const filtersearch = Data.filter((Data) => {
    if (!Searching) {
      if (SeleGR) {
        return Data.Group.includes(SeleGR);
      }
      return Data;
    } else {
      return Data.Name.includes(Searching);
    }
  });

  useEffect(() => {
    axios
      .get("https://gnhgzd-3000.csb.app/product")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
  const Group = Data.map((e) => e.Group).filter(
    (e, i, a) => a.indexOf(e) === i,
  );

  return (
    <animated.div
      className="Product"
      style={useSpring({ from: { y: +1200 }, to: { y: 0 }, delay: 80 })}
    >
      {/* LinK click */}
      <div className="Selet">
        <h2 className="Press">Press to select {SeleGR}</h2>
        <br />

        {Group.map((e, i) => {
          return (
            <div key={i} onClick={() => setSeleGR(e)}>
              <span>{e}</span>
              <span>Total {Data.filter((f) => f.Group === e).length}</span>
            </div>
          );
        })}

        <div onClick={() => setSeleGR("")}>
          <span>All</span>
          <div className="Total">
            <span>Total {Data.length}</span>
          </div>
        </div>

        <div>
          <input
            type="text"
            placeholder="ค้นหาชื่อ"
            style={{
              padding: "0.5rem",
              border: "none",
              width: "70%",
              fontSize: "1rem",
              boxShadow: "0 0 4px gray",
            }}
            onChange={function (e) {
              setSearching(e.target.value);
            }}
          />
        </div>
        <div>{Searching}</div>
        <br />
        <br />

        <section style={{ textAlign: "left" }}>
          <span style={{ lineHeight: "1.6" }}></span>
          <div>
            <button style={{ padding: "10px 14px", margin: "3rem 0px" }}>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "var(--blue-cl)" }}
              >
                Home
              </Link>
            </button>
          </div>
        </section>
      </div>

      {/* Items */}
      {!Data ? (
        "Loading..."
      ) : (
        <animated.div
          className="items"
          style={useSpring({ from: { x: +1200 }, to: { x: 0 }, delay: 420 })}
        >
          {/* Cards */}
          {filtersearch.map((Data) => (
            <Link
              className="card"
              key={Data._id}
              onClick={() => setoneread(Data._id)}
            >
              <img src={Data.Image} alt="" style={{ width: "100%" }} />
            </Link>
          ))}
        </animated.div>
      )}
      {/* OneRead */}
      {!oneRead ? (
        ""
      ) : (
        <div className="Card">
          <a
            href={Data.filter((e) => e._id === oneRead)[0].Image}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Data.filter((e) => e._id === oneRead)[0].Image}
              alt="PRODUCT"
            />
          </a>
          <div className="content">
            <div className="Contact">
              <h2>ชื่อ {Data.filter((e) => e._id === oneRead)[0].Name}</h2>
              <h3
                style={{
                  textDecoration: "underline",
                  color: "red",
                  cursor: "pointer",
                }}
              >
                ราคา {Data.filter((e) => e._id === oneRead)[0].Price}
              </h3>
            </div>
            <div className="Details">
              <span>
                <h3>ข้อมูล</h3> <br />
                {Data.filter((e) => e._id === oneRead)[0].Details}
              </span>
            </div>
            <button
              onClick={() => setoneread("")}
              style={{ padding: "8px 20px", margin: "2rem 0rem" }}
            >
              กลับ
            </button>
            <span style={{ textAlign: "left", lineHeight: "2" }}>
              <h3> สนใขสั่งซื้อ....</h3>
              <br />
              1.กรุณากรอก ชื่อเบอร์โทร พร้อมด้วย Email ผู้สนใจ
              <br />
              2. ตรงข้อความ ให้ใส่ ชื่อของสิงค้า พร้อมด้วยราคา
              <br />
              3. รอเจ้าหน้าที่ ติดต่อกลับ เพื่อยืนยันการสั่งซื้อ
              <br /> <br />
              --- กรณี เป็นงานด่วน ติดต่อสายตรงได้เลย ข้อมูลติดต่อ
              อยู่หน้า&nbsp; &nbsp; &nbsp;
              <a href="/about">contact</a>
            </span>
          </div>

          <EmailJs />
        </div>
      )}
    </animated.div>
  );
}
export default Product;
