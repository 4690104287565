import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import "./MessageBox.css";
import { MdChat, MdArrowDropDownCircle } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { FcBusinessman } from "react-icons/fc";

function MessageBox() {
  const form = useRef();

  const [Value, setValue] = useState();

  const sendEmail = async (e) => {
    alert(
      "สวัสดี\n" +
        "ขอบคุณที่ส่งข้อมูล\n" +
        "\n เมื่่อคุณกดตกลงแล้ว ข้อมูลจะถูกส่งไปที่ Addmin รอทำก่รภายใน 24ชม.",
    );
    e.preventDefault();
    let ddd = "";
    setValue([...ddd]);
    await emailjs
      .sendForm("service_644dvfd", "template_jlxal34", form.current, {
        publicKey: "doJ5WM1xM3AmF3umY",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        },
      );
  };

  const [H_ChatBox, setH_ChatBox] = useState("H_ChatBox");

  return (
    <div className="MessageBox">
      {/* Message click showbox กดแสดง*/}
      <div className="Box" onClick={() => setH_ChatBox("ChatBox")}>
        <MdChat className="Chat" />
      </div>

      {/* Message click H-box  */}
      <div className={H_ChatBox}>
        {/* Message click H-box one */}
        <div className="one" onClick={() => setH_ChatBox("H_ChatBox")}>
          <span>Closs</span>
          <MdArrowDropDownCircle />
        </div>

        {/* Message click H-box two */}
        <div className="two">
          <div className="L" onClick={() => setH_ChatBox("H_ChatBox")}>
            <span>ID Line</span>
            <a
              href="https://line.me/ti/p/-yPHknooDV"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://res.cloudinary.com/dxni450l4/image/upload/v1707891225/e5ujna0cyp1uaoa5rmip.png"
                alt=""
              />
            </a>
          </div>

          {/* Message click H-box R */}
          <div className="R" onClick={() => setH_ChatBox("H_ChatBox")}>
            <a
              className="Soc_icon"
              href="https://www.facebook.com/DxEMB"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare className="icons" id="facebook" />
              <label htmlFor="facebook">facebook</label>
            </a>
            <a
              className="Soc_icon"
              href="https://sites.google.com/view/dx-emb"
              target="_blank"
              rel="noreferrer"
            >
              <TbWorld className="icons" />
              <label htmlFor="facebook">Website</label>
            </a>
            <a
              className="Soc_icon"
              href="tel:091 045 5990"
              target="_blank"
              rel="noreferrer"
            >
              <IoCall className="icons" />
              <label htmlFor="facebook">Call me</label>
            </a>
          </div>

          {/* Message click H-box two Email */}
          <form className="ChatForm" ref={form} onSubmit={sendEmail}>
            <FcBusinessman className="F_icon" />
            <input
              type="text"
              placeholder="Your Name"
              name="name"
              value={Value}
            />
            <input
              type="email"
              placeholder="Your Email"
              name="email"
              value={Value}
            />
            <input
              type="tel"
              name="phone"
              placeholder="0912345678"
              value={Value}
            />
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}
export default MessageBox;
