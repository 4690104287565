import { CiCircleChevUp } from "react-icons/ci";
const ScrollTop = () => {
  function topFunction() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  const mystyle = {
    zIndex: "+999",
    width: "fit-content",
    position: "fixed",
    bottom: "2rem",
    left: "20px",
    fontSize: "18px",
    border: "none",
    outline: "none",
    backgroundColor: "var(--blue-cl)",
    color: "white",
    cursor: "pointer",
    padding: "4px",
    BorderRadius: "4px",
  };

  return (
    <button onClick={topFunction} title="Go to top" style={mystyle}>
      <CiCircleChevUp style={{ fontSize: "2rem" }} />
    </button>
  );
};
export default ScrollTop;
