import { useState } from "react";

// Style
import "./Upload.css";

// Data and Api
import Axios from "axios";

function Upload() {
  // ImageUpload
  const [Image, setImgurl] = useState();
  const [Name, setName] = useState();
  const [Group, setGroup] = useState();
  const [Price, setPrice] = useState();
  const [Details, setDetails] = useState();

  const uploadImage = (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "uvjjp6o3");
    Axios.post(
      "https://api.cloudinary.com/v1_1/dxni450l4/image/upload",
      formData,
    ).then((response) => {
      setImgurl(response.data.url);
    });
  };
  //  UploadItem
  async function CreadItem(e) {
    e.preventDefault();
    const OneCread = { Image, Name, Group, Price, Details };
    await Axios.post("https://gnhgzd-3000.csb.app/admin/post", OneCread).then(
      (response) => {
        setImgurl(response.data);
      },
    );
    setGroup();
  }
  return (
    <form className="Upload" onSubmit={CreadItem}>
      <div className="Image_up" style={{ border: "1px solid gray" }}>
        <input
          type="file"
          onChange={(even) => uploadImage(even.target.files)}
          required
        />
        <a href={Image} target="_blank" rel="noreferrer">
          <img
            src={Image}
            alt="ตัวอย่างรูป"
            style={{ width: "140px", padding: "4px 10px", margin: "10px" }}
          />
        </a>
      </div>
      {/* text */}
      <div className="Image_up">
        <input
          type="text"
          placeholder="ชื่อไฟล์"
          style={{ padding: "6px", width: "60%" }}
          onChange={(even) => setName(even.target.value)}
          required
        />
      </div>
      <div className="Image_up">
        <input
          type="text"
          placeholder="ชื่อกลุ่ม"
          style={{ padding: "6px", width: "50%" }}
          onChange={(even) => setGroup(even.target.value)}
          required
        />
      </div>
      <div className="Image_up">
        <input
          type="text"
          placeholder="ราคา"
          style={{ padding: "6px", width: "60%" }}
          onChange={(even) => setPrice(even.target.value)}
          required
        />
      </div>
      <div className="Image_up">
        <textarea
          type="text"
          placeholder="เกียวกับ"
          style={{ padding: "6px", width: "100%" }}
          onChange={(even) => setDetails(even.target.value)}
          required
        />
      </div>
      <div className="Image_up">
        <button type="submit" style={{ padding: "4px 10px", margin: "10px" }}>
          Upload
        </button>
        <button
          type="submit"
          style={{ padding: "4px 10px", margin: "10px", color: "red" }}
        >
          Cencel
        </button>
      </div>
    </form>
  );
}
export default Upload;
