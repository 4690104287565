import Home01 from "../Contener/Home/Home01";
import ProductShow from "../Contener/ProductShow/ProductShow";

function Home() {
  return (
    <div className="Home">
      <Home01 />
      <ProductShow /> 
    </div>
  );
}
export default Home;
