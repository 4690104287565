import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./EmailJS.css";

function EmailJs() {
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();
    alert("สวัสดี\n" + "ขอบคุณที่ส่งข้อมูล");
    emailjs
      .sendForm("service_644dvfd", "template_jlxal34", form.current, {
        publicKey: "doJ5WM1xM3AmF3umY",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        },
      );
  };
  return (
    <form className="EmailJs" ref={form} onSubmit={sendEmail}>
      {/* Hidden input */}
      <input type="hidden" name="AddFrom" value="React Fsv3000" />
      <h1>ส่งข้อมูลเพื่อติดต่อกลับ</h1>

      <div className="contents">
        <div className="L">
          <img
            src="https://media1.giphy.com/media/9PeDrIQTrNfA7PmaZT/giphy.gif?cid=6c09b95206lr6f5zg2uo7y4i6vx8kq0ivmz0xx2sixi18klb&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=s"
            alt=""
          />
        </div>

        <div className="R">
          <div>
            <label style={{ paddingRight: "1rem" }}>ชื่อ :</label>
            <input type="text" name="name" placeholder="Your Name" />
          </div>
          <div>
            <label style={{ paddingRight: "1rem" }}>เมล์ :</label>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
          </div>
          <div>
            <label style={{ paddingRight: "1rem" }}>เบอร์โทร :</label>
            <input type="tel" name="phone" placeholder="Younr Phone" required />
          </div>
          <div>
            <label style={{ paddingRight: "1rem" }}>ข้อความ :</label>
            <textarea name="descaption" placeholder="Younr Message" />
          </div>
        </div>
      </div>
      <button type="submit">Send</button>
    </form>
  );
}
export default EmailJs;
