import Skill from "../Contener/Skills/Skill";
import State from "../Contener/State/State";

function About() {
  return (
    <div className="About">
      <Skill />
      <State />
    </div>
  );
}
export default About;
