import "./Navig.css";
import { IoMenuSharp } from "react-icons/io5";
import { useState } from "react";
import { Link } from "react-router-dom";

function Navig() {
  // Navigbar Step
  const [Menubar, setMenubar] = useState("H_menu");
  const [bar, setBar] = useState("Hun");
  function ShowMenu() {
    setMenubar("S_menu");
    setBar("deBar");
  }
  function H_menu() {
    setMenubar("H_menu");
    setBar("Hun");
  }

  return (
    <div className="Navig">
      <header onClick={ShowMenu}>
        <IoMenuSharp className={bar} />
      </header>

      {/* Munebar */}
      <div className={Menubar} onClick={H_menu}>
        <Link className="Link" to="/">
          Home
        </Link>
        <Link className="Link" to="/product">
          Product
        </Link>
        <Link className="Link" to="/about">
          About
        </Link>
        <Link className="Link" to="/contact">
          Contact
        </Link>
        <Link className="Link" to="/addmin">
          Addmin
        </Link>
      </div>
    </div>
  );
}
export default Navig;
