import React from "react";

import "./Location.css";
function Location() {
  function Map() {
    return (
      <div className="Over">
        <iframe
          className="Lmap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d969.0324836530473!2d100.35417006959997!3d13.710579302058052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29608d64ae187%3A0x19c27861ea1e9c7c!2zMS0xMSDguIvguK3guKIg4LmA4Lie4LiK4Lij4LmA4LiB4Lip4LihIDEwNiDguYHguILguKfguIfguKvguJnguK3guIfguITguYnguLLguIfguJ7guKXguLkg4LmA4LiC4LiV4Lir4LiZ4Lit4LiH4LmB4LiC4LihIOC4geC4o-C4uOC4h-C5gOC4l-C4nuC4oeC4q-C4suC4meC4hOC4oyAxMDE2MA!5e0!3m2!1sth!2sth!4v1708673608017!5m2!1sth!2sth"
        ></iframe>
      </div>
    );
  }
  return (
    <div className="Location">
      <Map />
    </div>
  );
}
export default Location;
