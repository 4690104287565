import { animated, useSpring } from "react-spring";

// Icons
import { SiQualcomm } from "react-icons/si";
import { FaStar } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { RiUserFollowFill } from "react-icons/ri";
import { GrView } from "react-icons/gr";

// styles
import "./State.css";

function State() {
  // spring
  function CTState() {
    const springs = useSpring({
      from: { y: +1400 },
      to: { y: 0 },
      delay: 100,
    });
    function Like(n) {
      const { number } = useSpring({
        from: { number: 0 },
        to: { number: 67843 },
        number: n,
        delay: false,
        config: { mass: 1, friction: 20, tension: 1 },
      });
      return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
    }
    function Follow(n) {
      const { number } = useSpring({
        from: { number: 0 },
        to: { number: 45876 },
        number: n,
        delay: false,
        config: { mass: 1, friction: 20, tension: 1 },
      });
      return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
    }
    function View(n) {
      const { number } = useSpring({
        from: { number: 0 },
        to: { number: 97543 },
        number: n,
        delay: false,
        config: { mass: 1, friction: 20, tension: 1 },
      });
      return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
    }

    // Page show
    return (
      <animated.div className="CT_State" style={{ ...springs }}>
        <div className="content">
          {/* L contents */}
          <div className="L">
            <h1>Place of business</h1>
            <span>
              ซอยเพชรเกษม 106 แขวงหนองค้างพลู <br />
              เขตหนองแขม กรุงเทพมหานคร 10160
              <br />
              <br />
              <br />
              Nong Khang Phlu,
              <br /> Nong Khaem, Bangkok 10160 <br />
              <br />
              <h4>
                Phone : 091 045 5990 , Email : D27saitunlu@gmail.com , ID line:
                Sweedsiter
              </h4>
            </span>

            {/* iconItem */}
            <div className="iconItem">
              <div className="item">
                <h1>
                  <SiQualcomm />
                </h1>
                <span>Quality</span>
                <div>
                  <FaStar /> <FaStar /> <FaStar />
                  <FaStar />
                </div>
              </div>

              <div className="item">
                <h1>
                  <AiFillLike />
                </h1>
                <span>Like</span>
                <div>
                  <Like />
                </div>
              </div>

              <div className="item">
                <h1>
                  <RiUserFollowFill />
                </h1>
                <span>Follow</span>
                <div>
                  <Follow />
                </div>
              </div>

              <div className="item">
                <h1>
                  <GrView />
                </h1>
                <span>View</span>
                <div>
                  <View />
                </div>
              </div>
            </div>
          </div>

          {/* R contents */}
          <div className="R">
            <h3>Company image</h3>
            <img
              src="https://cdn.dribbble.com/users/130603/screenshots/7849095/media/6fb527d11feddd7fb364273cba6eebb8.gif"
              alt=""
            />
          </div>
        </div>
      </animated.div>
    );
  }
  return <CTState />;
}
export default State;
