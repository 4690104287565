import "./Header.css";

import { Link } from "react-router-dom";
import { CiLock, CiUnlock } from "react-icons/ci";

function Header() {
  let addmin = "";
  let Adstatus = false;
  if (Adstatus) {
    addmin = <CiUnlock />;
  } else {
    addmin = <CiLock />;
  }

  return (
    <div className="Header">
      <div className="L">
        <img src="https://res.cloudinary.com/dxni450l4/image/upload/v1710495714/e9mex7gdauceemwzypsl.png" />
      </div>
      <div className="R">
        <ul>
          <li>
            <Link className="Link" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="Link" to="/product">
              Product
            </Link>
          </li>
          <li>
            <Link className="Link" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="Link" to="/contact">
              Contact
            </Link>
          </li>
          <li>
            <Link className="Link addmin" to="/addmin">
              {addmin}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Header;
