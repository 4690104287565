import ProductShow01 from "./ProductShow01";
import ProductShow01R from "./ProductShow01R";

import "./ProductShow.css";

function ProductShow() {
  return (
    <div className="ProductShow">
      <h1 className="MouseSample">
        <span className="dot1"></span>
      </h1>
      <h1 className="NameSample">Product Sample</h1>

      <div className="firstItem">
        <ProductShow01 />
        <ProductShow01R />
      </div>
      <div className="lestItem"></div>
    </div>
  );
}
export default ProductShow;
