import { useState } from "react";

// Router Dom
import { Link } from "react-router-dom";

// Style CSS And Icons
import "./Login.css";
import { IoKeyOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoArrowBackCircleOutline } from "react-icons/io5";

// Data Api
import axios from "axios";

// Conponent
import Deshboard from "../../Components/Deshboard";

function Login() {
  const [Pass, setPass] = useState("password");
  const [token, setToken] = useState();
  const [AdMail, setAdMail] = useState();
  const [AdPass, setAdPass] = useState();

  function LoginAdmin(e) {
    e.preventDefault();
    const Data = { Name: AdMail, Passwort: AdPass };
    axios
      .post("https://gnhgzd-3000.csb.app/login", Data)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {
        setToken(err);
      });
  }

  function LogoutAdmin() {
    const Data = { Name: "", Passwort: "" };
    axios
      .post("https://gnhgzd-3000.csb.app/login", Data)
      .then((res) => {
        setToken(res.data);
      })
      .catch((err) => {
        setToken(err);
      });
  }

  return (
    <div className="Addmin">
      {/* <form action="post"> */}
      {!token && (
        <form onSubmit={LoginAdmin}>
          {/* L site */}
          <div className="L">
            <h1>know?</h1>
            <span>
              สำหรับการเข้าถึงหลังบ้านได้นั้น คุณต้องมี รหัสผ่าน
              ถึงสามารถเข้าถึงหรึอทำรายการต่อได้
            </span>
            <div className="Conect">
              <Link className="Link C_Link" to={"/"}>
                <IoArrowBackCircleOutline /> <span>กลับหน้าแรก</span>
              </Link>
            </div>
          </div>

          {/* R site */}
          <div className="R">
            <h1>You are Admin?</h1>
            <div>
              <input
                type="text"
                placeholder="Email"
                name="email"
                onChange={(e) => setAdMail(e.target.value)}
              />
              <CgProfile className="icon" />
            </div>
            <div>
              <input
                name="password"
                type={Pass}
                placeholder="password"
                onClick={() => setPass("password")}
                onChange={(e) => setAdPass(e.target.value)}
              />
              <IoKeyOutline className="icon" onClick={() => setPass("text")} />
            </div>
            <div>
              <button type="submit">เข้าระบบ</button>
            </div>
          </div>
        </form>
      )}
      {token && (
        <div style={{ marginTop: "2rem", width: "100%" }}>
          <Deshboard />
          <button
            style={{ margin: "2rem", padding: "4px 10px" }}
            onClick={LogoutAdmin}
          >
            ออกจากระบบ
          </button>
        </div>
      )}
    </div>
  );
}
export default Login;
