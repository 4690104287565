import "./styles.css";
import React from "react";
import { Routes, Route } from "react-router-dom";

// ดึงข้อมูล

import UserContext from "./Contener/Context/Context";

// แสดงหน้าเว็บ
import Header from "./Components/Header";
import Home from "./Components/Home";
import Product from "./Components/Product";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Addmin from "./Components/Addmin";
import Footer from "./Components/Footer";
import Navig from "./Contener/Navig/Navig";
import MessageBox from "./Contener/MessageBox/MessageBoc";
import ScrollTop from "./Contener/SCrollTop/ScrollTop";

// Component
export default function App() {
  return (
    <UserContext.Provider value="">
      <div className="App">
        <body>
          <div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </body>
        <div className="MainName">Dx-ebm Web And Design Tel : 091 045 5990</div>
        <Header />
        <Navig />
        <MessageBox />
        <Routes>
          <Route path="/" element="">
            <Route index exact element={<Home />} />
            <Route path="product" element={<Product />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="addmin" element={<Addmin />} />
          </Route>
        </Routes>

        <Footer />
        <ScrollTop />
      </div>
    </UserContext.Provider>
  );
}
