import Login from "../Contener/Login/Login";

import { animated, useSpring } from "react-spring";

function Addmin() {
  return (
    <animated.div
      className="Addmin"
      style={useSpring({ from: { y: +400 }, to: { y: 0 }, delay: 100 })}
    >
      <Login />
    </animated.div>
  );
}
export default Addmin;
