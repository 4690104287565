import { animated, useSpring } from "react-spring";
import ProfileIGM1 from "../../Avatar/Profile1.jpg";
import "./Skill.css";
function Skill() {
  function Myskill() {
    const springs = useSpring({
      from: { x: -1600 },
      to: { x: 0 },
      delay: 200,
    });

    const springs_R = useSpring({
      from: { y: +1600 },
      to: { y: 0 },
      delay: 600,
    });

    return (
      <animated.div className="Skill" style={{ ...springs }}>
        <div className="one">
          <h4>About our</h4>
          <h1>SPECIAL TEAM</h1>
          <div className="content">
            <span>
              เรามีทีม Suport ที่มีความสามารถที่จะช่วยเหลือคุณให้ครบถ้วน
              ไม่ว่าจะเป็นในประเทศและต่างประเทศ <br />
              จะเป็นลายปัก หรึอ ลายสกรีน ออกแบบ เรามีส่งให้ทั่วโลก
              <br />
              <br />
              <br />
              ที่ไม่มีมาแสดงให้เห็น เพราะสินค้าลิขสิทธ์ เลยไม่ได้เปิดเผล
              เพื่อให้เป็นการเข้าใจกันและกัน
              <br />
            </span>
          </div>
        </div>

        {/* two */}
        <div className="two">
          <animated.div className="" style={{ ...springs_R }}>
            <div className="items">
              <div className="item">
                <img
                  src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?w=826&t=st=1708327349~exp=1708327949~hmac=89fcd21932907501098d811d0ad5c31d52b466a3f93b37835eefd8feba92ab25"
                  alt=""
                />
                <span className="content">
                  name : Jons smill <br />
                  Phone : 091 234 5678 <br />
                  Email : Jons@gmail.com
                </span>
              </div>

              <div className="item">
                <img src={ProfileIGM1} alt="" />
                <span className="content">
                  name : Jons smill <br />
                  Phone : 091 234 5678 <br />
                  Email : Jons@gmail.com
                </span>
              </div>

              <div className="item">
                <img
                  src="https://img.freepik.com/free-photo/portrait-young-businesswoman-holding-eyeglasses-hand-against-gray-backdrop_23-2148029483.jpg?w=826&t=st=1708326953~exp=1708327553~hmac=da20ae12067d6e0e92e74fcda57442a4bc473814260853173fd5e1efd361d1ff"
                  alt=""
                />
                <span className="content">
                  name : Jons smill <br />
                  Phone : 091 234 5678 <br />
                  Email : Jons@gmail.com
                </span>
              </div>

              <div className="item">
                <img
                  src="https://img.freepik.com/free-photo/portrait-young-asian-businesswoman-with-her-arm-crossed-looking-camera-isolated-white-background_23-2148087156.jpg?w=826&t=st=1708326895~exp=1708327495~hmac=8c6889bf68d3b273e39c422b4d99cd86422cc58d31bee403d2d7e6ac34e68075"
                  alt=""
                />
                <span className="content">
                  name : Jons smill <br />
                  Phone : 091 234 5678 <br />
                  Email : Jons@gmail.com
                </span>
              </div>
            </div>
          </animated.div>
        </div>
      </animated.div>
    );
  }
  return <Myskill />;
}
export default Skill;
