import Slider from "react-slick";
import "./ProductShow01.css";

function ProductShow01() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1800,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container ProductShow01">
      <Slider {...settings}>
        <div>
          <img
            src="https://cdn.diys.com/wp-content/uploads/2020/05/lovecrafts-Summer-Party-Bouquet.jpg"
            alt=""
          />
        </div>
        <div>
          <img
            src="https://i.etsystatic.com/33412874/r/il/4fac91/3624877167/il_300x300.3624877167_50gb.jpg"
            alt=""
          />
        </div>{" "}
        <div>
          <img src="https://pbs.twimg.com/media/EyfeMkoU4AAB236.jpg" alt="" />
        </div>{" "}
        <div>
          <img
            src="https://down-th.img.susercontent.com/file/b8603eba29c4b941e1216b63265f7f2a_tn"
            alt=""
          />
        </div>
      </Slider>
    </div>
  );
}
export default ProductShow01;
