import { Link } from "react-router-dom";

import "./Footer.css";

import { MdOutlineMapsHomeWork } from "react-icons/md";
import { PiLinkSimpleFill } from "react-icons/pi";
import { IoMdContacts } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

import {
  FaPhoneSquareAlt,
  FaMailBulk,
  FaFacebookMessenger,
  FaLine,
} from "react-icons/fa";
function Footer() {
  return (
    <div className="Footer">
      <div className="FT01">
        <h1 className="FT_icon">
          <MdOutlineMapsHomeWork />
        </h1>
        <div>
          ซอยเพชรเกษม 106 แขวงหนองค้างพลู เขตหนองแขม กรุงเทพมหานคร 10160 Nong
          Khang Phlu, Nong Khaem, Bangkok 10160
        </div>
      </div>

      <div className="FT02">
        <h1 className="FT_icon">
          <PiLinkSimpleFill />
        </h1>
        <Link className="Link" to="/">
          Home
        </Link>
        <br />
        <Link className="Link" to="/product">
          Product
        </Link>
        <br />
        <Link className="Link" to="/about">
          About
        </Link>
        <br />
        <Link className="Link" to="/contact">
          Contact
        </Link>
        <br />
        <Link className="Link" to="/addmin">
          Addmin
        </Link>
      </div>
      <div className="FT03">
        <h1 className="FT_icon">
          <IoMdContacts />
        </h1>

        <a href="tel: 0910455990" target="_blank" rel="noreferrer">
          โทร : 091 045 5990
        </a>
        <br />
        <a href="mailto:D27saitunlu@gmail.com" target="_blank" rel="noreferrer">
          Mail :D27saitunlu@gmail.com
        </a>
        <br />
        <a
          href="https://line.me/ti/p/-yPHknooDV"
          target="_blank"
          rel="noreferrer"
        >
          ID Line : Sweedsiter
        </a>
        <br />
        <a
          href="https://www.facebook.com/DxEMB"
          target="_blank"
          rel="noreferrer"
        >
          Faceboock : DxEmb
        </a>
        <div className="socail">
          <a href="tel: 0910455990" target="_blank" rel="noreferrer">
            <FaPhoneSquareAlt />
          </a>
          <a
            href={"mailto:" + "d27saitunlu@gmail.com"}
            target="_blank"
            rel="noreferrer"
          >
            <FaMailBulk />
          </a>
          <a
            href="https://line.me/ti/p/-yPHknooDV"
            target="_blank"
            rel="noreferrer"
          >
            <FaLine />
          </a>
          <a
            href="https://www.facebook.com/DxEMB"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookMessenger />
          </a>
        </div>
      </div>
      <div className="FT04">
        <h1 className="FT_icon">
          <FaLocationDot />
        </h1>
        <iframe
          className="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d969.0324836530473!2d100.35417006959997!3d13.710579302058052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29608d64ae187%3A0x19c27861ea1e9c7c!2zMS0xMSDguIvguK3guKIg4LmA4Lie4LiK4Lij4LmA4LiB4Lip4LihIDEwNiDguYHguILguKfguIfguKvguJnguK3guIfguITguYnguLLguIfguJ7guKXguLkg4LmA4LiC4LiV4Lir4LiZ4Lit4LiH4LmB4LiC4LihIOC4geC4o-C4uOC4h-C5gOC4l-C4nuC4oeC4q-C4suC4meC4hOC4oyAxMDE2MA!5e0!3m2!1sth!2sth!4v1708673608017!5m2!1sth!2sth"
        ></iframe>
      </div>
    </div>
  );
}
export default Footer;
