import EmailJs from "../Contener/Contact/EmailJs";
import Location from "../Contener/Location/Location";
import State from "../Contener/State/State";

function Contact() {
  return (
    <div className="Contact">
      <State />
      <EmailJs />
      <Location />
    </div>
  );
}
export default Contact;
