import "./ProductShow01R.css";
import { FaStar, FaRegStar } from "react-icons/fa";
import { AiFillLike, AiOutlineDislike } from "react-icons/ai";
import { animated, useSpring } from "react-spring";
import { Link } from "react-router-dom";
function ProductShow01R() {
  function Number(n) {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: 67843 },
      number: n,
      delay: false,
      config: { mass: 1, friction: 20, tension: 1 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }
  function Number2(n) {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: 643 },
      number: n,
      delay: false,
      config: { mass: 1, friction: 20, tension: 50 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }

  return (
    <div className=" ProductShow01R">
      {/* one */}
      <div className="one">
        <div className="L"></div>

        <div className="R">
          <h1>
            OUR <br />
            EMBROIDERY <br /> CLOTHES <br />
            DESIGN
          </h1>
        </div>
      </div>

      {/* two */}
      <div className="two">
        รับออกแบบ โดย โปรแกรม Ai illustrator ไม่ว่าจะเป็น โลโก้ สิ่งพิมพ์
        สือออนไลน์ ทุกประเภท หรึอ จะนำไปใช้กับ สกรีน Flex / DTG /DTF / DFT / DTG
        UV / Laser /Screen sublimation / สกรีน แยกสี เลเยอร์
        เรารับทำให้ครบจบที่เดี่ยว..
        <br />
        <br />
        <br />
        รับเขียนลายปัก เย็บปักถักร้อย / Embroidery ทุกประเภท จะออกแบบ หรึอเป็น
        รูปแบบตามที่มี ทางเรารับเขียนให้ ไม่ว่าจะเป็น ลายปักฟู ปัก-Change
        ปัก-Cross.stitch ปัก-Handmake ส่งไฟล์เป็นนามสกุล EMB / DST / PES
        ให้ลูกค้า แก้ใขงานให้ตรงตามความต้องการ
      </div>

      <div className="click_bt">
        <Link className="Link" to="/contact">
          <button>ติดต่อเรา</button>
        </Link>
      </div>

      <div className="parsen">
        <div className="stars">
          <FaStar className="star" /> <FaStar className="star" />{" "}
          <FaStar className="star" />
          <FaRegStar className="star" />
          <FaRegStar className="star" />
        </div>

        <div className="likes">
          <div className="like">
            <AiFillLike />
            <span>
              <Number n={0} />
            </span>
          </div>

          <div className="like">
            <AiOutlineDislike />
            <span>
              {" "}
              <Number2 n={0} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductShow01R;
