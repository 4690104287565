import { animated, useSpring } from "react-spring";
import { Link } from "react-router-dom";

import "./Home01.css";

function Home01() {
  function Page1() {
    const springs = useSpring({
      from: { y: +800 },
      to: { y: 0 },
      delay: 0,
    });

    return (
      <animated.div className="Over" style={{ ...springs }}>
        <div className="Home01">
          <div className="one">
            <h1>Dx-ebm ...</h1>
            <div className="FullName">
              <h4>Web And</h4>
              <h4> Designer</h4>
            </div>
            <span>
              Dx-ebm design รับออกแบบ ลายปัก ลายสกรีน ทุกประเภท รับเขียนลายปัก
              โดยมืออาชีพ มีประสบการณ์มากกว่า 21 ปี ไว้ใจได้ 100% บริการเอาใจใส่
              ส่งมอบตามตรงต่อเวลา มีคุณภาพ ราคาถูกกว่าที่อื่นอย่างแน่นอน
              สนใจสอบถามทุกช่องทาง <br /> .........
            </span>
            <div>
              <Link className="Link" to="/product">
                <button>ดูตัวอย่างาน</button>
              </Link>
            </div>
          </div>

          {/* two */}
          <div className="Two">
            <img
              src="https://www.mesamachines.com/global-includes/machines/images/Highland-1501-Breeze-480x480.png"
              alt=""
            />
          </div>
        </div>
      </animated.div>
    );
  }
  return <Page1 />;
}
export default Home01;
