import Form from "../Contener/Form/Upload";

// Data Api

function Deshboard() {
  return (
    <div>
      <h1>Admin Deshboard Welcome</h1>
      <Form />
    </div>
  );
}

export default Deshboard;
